import { Inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    protected isVisible$ = new Subject();
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

    public onChangeModal(data: boolean) {
        return this.isVisible$.next(data);
    }

    public get isVisible() {
        return this.isVisible$;
    }
}
